<script>
	import { Ghost, ShieldCheck } from 'lucide-svelte';
</script>

<div class="mt-12 bg-slate-50 rounded-lg p-8 md:p-12">
	<h2 class="text-3xl font-semibold leading-[1.15]">
		A powerful dashboard to monitor email issues.
	</h2>

	<p class="mt-5 text-lg md:w-9/12">
		Set up
		<span class="font-medium">DMARC</span>
		with our guidance to
		<mark class="font-medium">gain visibility</mark>
		into your email delivery through a comprehensive
		<mark class="font-medium">aggregate reporting</mark>
		dashboard.
	</p>

	<div class="mt-10 grid md:grid-cols-2 gap-x-20 gap-y-12 text-lg">
		<div class="flex flex-col gap-y-4">
			<ShieldCheck class="mr-2 size-12" strokeWidth="1.5" />
			Find out why your emails are not reaching the inbox and fix authentication issues.
		</div>

		<div class="flex flex-col gap-y-4">
			<Ghost class="mr-2 size-12" strokeWidth="1.5" />
			See who's sending using your domains and harden your DMARC configuration.
		</div>
	</div>

	<enhanced:img src="../../images/dashboard.png" alt="Dashboard"
								class="mt-12 w-full rounded-lg shadow-2xl" />
</div>
