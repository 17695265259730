<script>
	import { Forward, Ghost, ShieldCheck } from 'lucide-svelte';
</script>

<div class="mt-12 bg-slate-50 rounded-lg md:p-12 p-8 grid md:grid-cols-2 gap-x-20 gap-y-12">
	<div>
		<h2 class="text-3xl font-semibold leading-[1.15]">
			Stay updated with weekly email digests.
		</h2>

		<p class="mt-5 text-lg">
			No need to come back to the dashboard!
			<mark class="font-medium">Key insights</mark>
			will be delivery by email every week, highlighting potential issues.
		</p>

		<p class="mt-5 text-lg">
			Categorized sources help distinguish legitimate and malicious sending activity:
		</p>

		<ul class="mt-10 text-lg space-y-3 font-medium">
			<li>
				<ShieldCheck class="size-8 inline-block mr-2" strokeWidth="1.5" />
				Authorized sources
			</li>
			<li>
				<Forward class="size-8 inline-block mr-2" strokeWidth="1.5" />
				Forwarded / unaligned sources
			</li>
			<li>
				<Ghost class="size-8 inline-block mr-2" strokeWidth="1.5" />
				Non-compliant sources
			</li>
		</ul>

		<p class="mt-10 text-lg">
			Known and popular sources are also mapped to their product names.
			"Mailchimp" is easier than <code>mcsv.net</code>,
			isn't it?
		</p>
	</div>

	<enhanced:img src="../../images/digests.png" alt="Email digest"
								class="rounded-lg shadow-2xl" />
</div>
