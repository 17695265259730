<div class="mt-12 bg-slate-50 rounded-lg md:p-12 p-8">
	<h2 class="text-3xl font-semibold leading-[1.15]">
		Track unauthorized changes of your DNS records.
	</h2>

	<p class="mt-5 text-lg md:w-9/12">
		Changes in your <code>_dmarc</code> DNS record are automatically detected, so
		you'll always be able to
		<mark class="font-medium">see the history</mark>
		of your domain configuration.
	</p>

	<enhanced:img src="../../images/dns-history.png" alt="Dashboard"
								class="mt-12 w-full rounded-lg shadow-2xl" />
</div>
